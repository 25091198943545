<template>
  <div class="row m-0 px-lg-4">
    <div class="col-12 px-lg-5">
      <div class="row m-0">
        <!--PERSONAL DETAILS AND PREFERENCES PAGE-->
        <div class="col-12 p-0 overflow_y h-100" v-if="detailsActivePage === 'personalDetails' ||  detailsActivePage === 'preferences'">
          <questionnaire background="dark" :personal="detailsActivePage === 'personalDetails'" :preferences="detailsActivePage === 'preferences'" @saved="proceed" />
        </div>
        <!--END PERSONAL DETAILS AND PREFERENCES PAGE-->
        <!--PASSPORT PAGE-->
        <div class="col-12 p-0" v-else-if="detailsActivePage === 'passportDetails'">
          <passport-details background="dark" :personal=true />
        </div>
        <!--PASSPORT PAGE-->
        <!--CHANGE PASSWORD PAGE-->
        <div class="col-12 p-0" v-else-if="detailsActivePage === 'changePassword'">
          <change-password background="dark" :personal=true></change-password>
        </div>
        <!--CHANGE PASSWORD PAGE-->
        <!--ADD MEMBER PAGE-->
        <div class="col-12 p-0 overflow_y h-100" v-else-if="detailsActivePage === 'addMember'">
          <member-details background="dark" :personal=false></member-details>
        </div>
        <!--END ADD MEMBER PAGE-->
        <!--HISTORY PAGE-->
        <div class="col-12 p-0" v-else>
        </div>
        <!--END HISTORY PAGE-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DetailsPage',
  props: ['detailsActivePage'],
  components: {
    'change-password': () => import('@/components/ChangePassword.vue'),
    'passport-details': () => import('@/components/PassportDetails.vue'),
    questionnaire: () => import('@/components/GuestQuestionnaire.vue'),
    'member-details': () => import('@/components/MemberDetails.vue')
  },
  methods: {
    proceed (val) {
      switch (val) {
        case 'preferences':
          this.$emit('changeSubPage', val)
          break
        default:
          this.$emit('changeActivePage', val)
          break
      }
    }
  }
}
</script>
<style scoped>
</style>
